<template lang="pug">
TableV2(
  :headers="headers"
  :items="dealingById.ntz_ratio"
  isHiddenTableSearch
  isHiddenTableCount
  isHiddenPageCount).px-4
</template>

<script>

import { mapGetters, mapState } from 'vuex'
export default {
  name: 'InfoDealing',
  props: {
    dealingById: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'ntz.name_ukr', text: this.$t('eti'), width: '50%', sortable: false },
        { value: 'ratio', text: this.$t('ratio'), width: '50%', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      namelang: state => state.main.lang === 'en' ? 'name_ukr' : 'name_eng'
    })
  }
}
</script>
